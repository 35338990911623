<template>
  <div class="Discussion">
    <div class="Discussion-title">
      <div class="title">直播间设置-讨论</div>
      <div>开启状态：
        <a-switch
            style="margin-right: 10px"
            v-model="isShow"
            @change="switchChange(isShow)"
            checked-children="开"
            un-checked-children="关"
            default-checked />
      </div>
    </div>
    <div class="Discussion-content">
      <div style="display: flex;justify-content: right">
        <a-input-search
            v-model="titleVal"
            style="width: 400px;margin-bottom: 20px;"
            placeholder="请输入发言人姓名或内容关键字"
            enter-button="搜索"
            allow-clear
            @search="onSearch"
        />
      </div>
      <a-table :columns="columns" :data-source="data" :pagination="false" :row-key="record => record.id">
        <span slot="name" slot-scope="text, record">
          <a @click="openComment(record)">{{record.name}}</a>
        </span>
        <span slot="action" slot-scope="text, record">
            <a-popconfirm
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="confirm(record.id)"
            ><a href="#">删除</a>
            </a-popconfirm>
        </span>
      </a-table>
      <div style="width: 100%;margin-top: 20px" v-show="data.length">
        <div style="display: flex;justify-content: center">
          <a-pagination
              show-quick-jumper
              :pageSize="pageSize"
              :current="pageNo"
              :total="total"
              @change="paginationChange" />
        </div>
        <div style="height: 10px"></div>
      </div>
    </div>
    <!--  评论以及加入黑名单对话框  -->
    <a-drawer
        :title="commentTitle"
        placement="right"
        :visible="commentVisible"
        width="800px"
        :footer="null"
        @close="onClose"
    >
      <a-alert type="info" show-icon>
        <span slot="description">
          <div>禁言：将该账号禁言后将无法在本次直播间里进行任何言语评论</div>
          <div style="margin-top: 7px;margin-bottom: 7px">加入黑名单：将该账号加入黑名单后将无法再查看该企业下所有直播会议</div>
          <div style="color: red">以上操作会在直播间对该用户进行提示</div>
        </span>
      </a-alert>
      <div style="padding-bottom: 20px;border-bottom: 1px solid #F5F5F5">
        <div style="margin-top: 20px;font-weight: bold;">账号信息</div>
        <div style="display: flex;justify-content: space-between">
          <div>
            <div style="margin-top: 10px;margin-bottom: 10px">用户名称： {{commentContent.name}}</div>
            <div>科室：{{commentContent.hospitalOffices}}</div>
          </div>
          <div>
            <div style="margin-top: 10px;margin-bottom: 10px" >用户手机号： {{commentContent.tel}}</div>
            <div>职称：{{commentContent.jobTitle}}</div>
          </div>
          <div>
            <div style="margin-top: 10px;margin-bottom: 10px">所在单位： {{commentContent.hospitalName}}</div>
            <div>
              <a-popconfirm
                  v-if="commentContent.isShutUp"
                  title="是否取消禁言?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="cancelComment"
              >
                <a style="color: red">已禁言</a>
              </a-popconfirm>
              <a-popover v-else title="禁言" v-model="postVisible" trigger="click">
                <template slot="content">

                  <div>是否确认禁言？</div>
                  <div style="color: red">禁言后将无法在本直播间进行任何言语评论</div>
                  <a-select
                      v-if="postSpeak !== '其他'"
                      placeholder="请选择"
                      v-model="postSpeak"
                      class="postSpeakSty"
                      @change="postSpeakChange">
                    <a-select-option v-for="list in postSpeakList" :key="list.id" :value="list.title">
                      {{list.title}}
                    </a-select-option>
                  </a-select>
                  <a-input  class="postSpeakSty" v-else v-model="postSpeakInput" placeholder="请输入禁言原因"></a-input>
                  <div style="display: flex;justify-content: right">
                    <a-button @click="closePost" size="small">取 消</a-button>
                    <a-button @click="okPost" size="small" type="primary" style="margin-left: 10px">确 认</a-button>
                  </div>
                </template>

                <a>禁言</a>
              </a-popover>

              <a-popconfirm
                  style="margin-left: 8px;margin-right: 8px"
                  v-if="commentContent.isBlack"
                  title="是否取消黑名单设置?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="cancelBlacks"
              >
                <a style="color: red">已加入黑名单</a>
              </a-popconfirm>
              <a-popover v-else title="加入黑名单" v-model="blacklistVisible" trigger="click" style="margin-left: 8px;margin-right: 8px">
                <template slot="content">
                  <div>是否确认加入黑名单？</div>
                  <div style="color: red">加入黑名单后将无法观看该企业下所有会议</div>
                  <a-select
                      v-if="blacklist !== '其他'"
                      placeholder="请选择"
                      v-model="blacklist"
                      class="postSpeakSty"
                      @change="blacklistChange">
                    <a-select-option v-for="list in postSpeakList" :key="list.id" :value="list.title">
                      {{list.title}}
                    </a-select-option>
                  </a-select>
                  <a-input  class="postSpeakSty" v-else v-model="blacklistInput" placeholder="请输入加入黑名单原因"></a-input>
                  <div style="display: flex;justify-content: right">
                    <a-button @click="closeBlacklist" size="small">取 消</a-button>
                    <a-button @click="okBlacklist" size="small" type="primary" style="margin-left: 10px">确 认</a-button>
                  </div>
                </template>
                <a>加入黑名单</a>
              </a-popover>

              <span style="color: red;">已禁言{{commentContent.shutUpNumber}}次</span>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;margin-top: 20px">
        <a-popconfirm
            title="是否确认批量删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="delComment"
        >
          <a-button><a-icon style="color: red" type="close-circle" /> 批量删除</a-button>
        </a-popconfirm>
        <a-input-search
            v-model="modelInput"
            allow-clear
            style="width: 300px"
            placeholder="请输入评论内容"
            enter-button="搜索"
            @search="onSearchs"
        />
      </div>
      <a-table
          :pagination="false"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columnsComment"
          :data-source="commentContent.chatList"
          :row-key="record => record.id"
          style="margin-top: 20px">
        <span slot="system" slot-scope="text, record,index">
         <a-popconfirm
             title="是否确认删除?"
             ok-text="是"
             cancel-text="否"
             @confirm="confirmComment(record.id)"
         ><a href="#">删除</a>
            </a-popconfirm>
        </span>
      </a-table>
    </a-drawer>
  </div>
</template>

<script>
import {
  liveChatRecord,
  getLiveChatRecord,
  putStreamFunc,
  memberAndChat,
  insertShutUp, insertBlackList, removeShutUp, putRemoveBlack
} from "@/service/liveManage";

export default {
name: "Discussion",
  data(){
    return{
      isShow:false,
      columns:[
        {
          title: '内容',
          dataIndex: 'message',
          key: 'message',
        },{
          title: '发言人',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
        },{
          title: '所在单位',
          dataIndex: 'unit',
          key: 'unit',
        },{
          title: '发言时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
        },{
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data:[],
      pageSize:10,
      pageNo:1,
      total:0,
      titleVal:'',
      commentTitle:'韩笑',
      commentVisible:false,
      commentMemberId:null,
      modelInput:'',
      columnsComment:[
        {
          title:'评论内容',
          dataIndex: 'message',
          key: 'message',
        },{
          title:'评论时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
        },{
          title:'操作',
          scopedSlots: { customRender: 'system' },
        },
      ],
      selectedRowKeys: [],
      postSpeak:undefined,
      postSpeakInput:'',
      blacklistInput:'',
      blacklist:undefined,
      postSpeakList:[
        {
          id:1,
          title:'发言含有低俗/不雅词汇',
        },{
          id:2,
          title:'发言含有挑衅词汇',
        },{
          id:3,
          title:'发言含有侮辱性词句',
        },{
          id:4,
          title:'刷屏',
        },{
          id:5,
          title:'其他',
        },
      ],
      postVisible:false,
      blacklistVisible:false,
      funVisible:false,
      commentContent:{},
    }
  },
  computed:{
    funListId(){
      return this.$store.state.funList;
    }
  },
  created() {
  this.liveChatRecord();
  },
  methods:{
    async cancelComment() {
      let data = {
        "streamId":this.funListId.streamId,//直播间id
        "memberId":this.commentMemberId //被禁言人id
      }
      const response = await removeShutUp(data)
      if (response.code === 200) {
        this.$message.success("删除成功！");
        await this.memberAndChats(this.commentMemberId);
      } else {
        this.$message.warning(response.message);
      }
    },
    async cancelBlacks() {
      let data = {
        "memberId": this.commentMemberId //被禁言人id
      }
      const response = await putRemoveBlack(data)
      if (response.code === 200) {
        this.$message.success("删除成功！");
        await this.memberAndChats(this.commentMemberId);
      } else {
        this.$message.warning(response.message);
      }
    },
    confirmComment(id){
      this.liveChatRecords(id);
    },
    async liveChatRecords(liveChatIds) {
      const response = await liveChatRecord(liveChatIds)
      if (response.code === 0) {
        this.$message.success("删除成功！");
        await this.memberAndChats(this.commentMemberId);
      } else {
        this.$message.warning(response.message);
      }
    },
    closePost(){
      this.postVisible = false;
      this.postSpeak = undefined;
      this.postSpeakInput = '';
    },
    okPost(){
      if(this.postSpeak){
        let data = {};
        if(this.postSpeak === '其他'){
          data = {
            "streamId": this.funListId.streamId,//直播间id
            "memberId": this.commentMemberId,//被禁言人id
            "reason": this.postSpeakInput //原因
          }
        }else {
          data = {
            "streamId": this.funListId.streamId,//直播间id
            "memberId": this.commentMemberId,//被禁言人id
            "reason": this.postSpeak //原因
          }
        }
        this.insertShutUps(data);
      }else {
        this.$message.warning("请选择或者输入禁言理由！")
      }
    },
    //设置禁言
    async insertShutUps(data) {
      const response = await insertShutUp(data)
      if (response.code === 200) {
        this.$message.success("禁言成功！");
        await this.memberAndChats(this.commentMemberId);
        this.closePost();
      } else {
        this.$message.warning(response.message);
      }
    },
    okBlacklist(){
      if(this.blacklist){
        let data = {};
        if(this.blacklist === '其他'){
          data = {
            "memberId": this.commentMemberId,//被禁言人id
            "reason": this.blacklistInput //输入原因
          }
        }else {
          data = {
            "memberId": this.commentMemberId,//被禁言人id
            "reason": this.blacklist //选择原因
          }
        }
        this.insertBlackLists(data);
      }else {
        this.$message.warning("请选择或者输入加入黑名单理由！")
      }
    },
    //设置加入黑名单
    async insertBlackLists(data) {
      const response = await insertBlackList(data)
      if (response.code === 200) {
        this.$message.success("加入黑名单成功！");
        await this.memberAndChats(this.commentMemberId);
        this.closeBlacklist();
      } else {
        this.$message.warning(response.message);
      }
    },
    closeBlacklist(){
      this.blacklistVisible = false;
      this.blacklist = undefined;
      this.blacklistInput = '';
    },
    openComment(row){
      this.commentVisible = true;
      this.commentMemberId = row.memberId;
      this.memberAndChats(row.memberId);
    },
    //获取发言人信息和在当前直播间的评论
    async memberAndChats(memberId) {
      const response = await memberAndChat(this.funListId.streamId,memberId,this.modelInput)
      if (response.code === 200) {
        this.commentContent = response.data;
      } else {
        this.$message.warning(response.message);
      }
    },
    onClose(){
      this.commentVisible = false;
      this.liveChatRecord();
    },
    onSelectChange(selectedRowKeys){
      //选择的列表的id
      this.selectedRowKeys = selectedRowKeys;
    },
    onSearchs(){
      this.memberAndChats(this.commentMemberId);
    },
    delComment(){
      if(this.selectedRowKeys.length){
        let liveChatIds = this.selectedRowKeys.join();
        this.liveChatRecords(liveChatIds);
      }else {
        this.$message.warning("请先选择数据，在进行批量删除操作！")
      }
    },
    blacklistChange(){

    },
    postSpeakChange(){

    },

    //获取讨论列表
    async liveChatRecord() {
      let data = {
        page_no:this.pageNo,
        page_size:this.pageSize,
      }
      const response = await getLiveChatRecord(this.funListId.streamId,this.titleVal,data)
      if(response.code === 0){
        this.data = response.data;
        this.total = response.count;
        this.isShow = response.isShow ? true:false;
      }else {
        this.$message.warning(response.message);
      }
    },
    paginationChange(pageNo,pageSize){
      this.pageNo = pageNo;
      this.liveChatRecord();
    },
    switchChange(isShow){
      this.isShow = isShow;
      let data = {
        "id": this.funListId.id, //id
        "streamId": this.funListId.streamId, //直播间id
        "functionCode": this.funListId.functionCode, //功能code值
        "isShow":isShow ? 1:0  //0 关闭 1开启
      }
      this.putStreamFuncs(data);
    },
    // 修改直播间设置功能列表
    async putStreamFuncs(data) {
      const response = await putStreamFunc(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        //刷新数据
        await this.liveChatRecord();
      }else {
        this.$message.warning(response.message);
      }
    },
    async confirm(id) {
      const response = await liveChatRecord(id);
      if (response.code === 0) {
        this.$message.success('删除成功！');
        await this.liveChatRecord();
      } else {
        this.$message.warning(response.message);
      }
    },
    onSearch(){
      this.liveChatRecord();
    },
  }
}
</script>

<style scoped lang="scss">
.Discussion {
    width: 100%;
    //border-left: 1px solid #d9d9d9;
  .Discussion-title {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .title {
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .Discussion-content{
    width: 98%;
    margin: auto;
  }
}
.postSpeakSty{
  //width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
</style>
